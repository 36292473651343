import IMOG from '~/lib/imog';
import { Mesh, Geometry, Program, Plane } from 'ogl';

export default IMOG.Component('WallBG', {
  setup({ options }) {
    this.bg = new Mesh(this.$gl, {
      geometry: new Plane(this.$gl, {
        width: 1,
        height: 1,
        widthSegments: 50,
        heightSegments: 50,
      }),
      program: new Program(this.$gl, {
        vertex: `attribute vec3 position;
           attribute vec3 normal;
           uniform mat4 modelViewMatrix;
           uniform mat4 projectionMatrix;
           attribute vec2 uv;
           varying vec2 vUv;
           void main() {
               vUv = uv;
               vec3 p = position;
               p.z -= 2.0 * cos(p.x * 3.1416 * 0.5);
               p.xy *= vec2(12.0, 5.0) * 2.2;
               gl_Position = projectionMatrix * modelViewMatrix * vec4(p, 1.0);
           }
        `,
        fragment: `precision highp float;

          float rand(float n){return fract(sin(n) * 43758.5453123);}

          vec3 pal( in float t, in vec3 a, in vec3 b, in vec3 c, in vec3 d ){
              return a + b*cos( 6.28318*(c*t+d) );
          }

          vec3 rgb2hsv(vec3 c){
              vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
              vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
              vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

              float d = q.x - min(q.w, q.y);
              float e = 1.0e-10;
              return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
          }

          vec3 hsv2rgb(vec3 c){
              vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
              vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
              return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
          }

          varying highp vec2 vUv;
          void main() {
              // vec3 darkGreen = vec3(10. / 255., 28. / 255., 150. / 255.);
              vec3 darkGreen = vec3(0.,0.,0.);

              vec3 result = darkGreen;

              result += vec3(0.008,0.09,0.624) * 0.2;

              vec3 hsl = rgb2hsv(result);
              // hsl.x -= 0.1;
              result = hsv2rgb(hsl);

              gl_FragColor.rgb = result;
              gl_FragColor.a = 1.0;

              ${this.$darkGrading
            ? `
                  vec3 fHSV = rgb2hsv(gl_FragColor.rgb * 0.);
                  // fHSV.r -= 0.15;
                  // fHSV.g -= 0.5;
                  // fHSV.b -= 0.35;
                  gl_FragColor.rgb = hsv2rgb(fHSV);
                  `
            : ``
          }


          }
        `,
        uniforms: {},
        transparent: true,
      }),
    });
    this.bg.position.z = -1;
    this.bg.setParent(options.parent);
  },
});
