import _ from 'underscore';
import IMOG from '~/lib/imog';
import {
  RenderTarget,
  Mesh,
  Geometry,
  Camera,
  Program,
  Color,
  Vec2,
  Vec3,
  Transform,
  Post,
} from 'ogl';
import useWindowSize from '~/lib/imog/use/windowSize';
import useMouse from '~/lib/imog/use/mouse';
import useSpring from '~/lib/imog/use/spring';

import Bg from './Bg';
import Wall from './Wall';

// function glsl(...args) {
//   console.log(
//     args[0].raw
//       .reduce((memo, r, i) => memo + r + (args[i + 1] || ''), '')
//       .replace(/\\n/gi, '')
//   );
//   return args[0].raw
//     .reduce((memo, r, i) => memo + r + (args[i + 1] || ''), '')
//     .replace(/\\n/gi, '');
// }

const triangle = _.memoize(
  (gl) =>
    new Geometry(gl, {
      position: { size: 2, data: new Float32Array([-1, -1, 3, -1, -1, 3]) },
      uv: { size: 2, data: new Float32Array([0, 0, 2, 0, 0, 2]) },
    })
);

export default IMOG.Component('Scene', {
  props() {
    return {
      windowSize: useWindowSize(),
      pr: 2,
      size: (props) => ({
        width: props.windowSize.width,
        height: props.windowSize.height,
        pr: props.pr,
      }),
      mouse: useMouse(),
      mouseXSpring: useSpring({
        friction: 0.1,
        target: (props) => props.mouse.x,
      }),
      mouseYSpring: useSpring({
        friction: 0.1,
        target: (props) => props.mouse.y,
      }),
      cameraXY: (props) => ({
        x: props.mouseXSpring.value,
        y: props.mouseYSpring.value,
      }),
    };
  },

  setup({ options }) {
    this.scene = new Transform(this.$gl);
    this.scene.scale.set(0.9, 0.9, 0.9);
    this.camera = new Camera(this.$gl, { fov: 40, far: 10000 });
    this.camera.position.set(0, 0, 7);
    IMOG.inject('sceneCamera', this.camera);
    // this.camera.lookAt([0, 0, 0]);

    // Create composite post at full resolution, bg post at full resolution, and bloom at reduced resolution
    this.postComposite = new Post(this.$gl);
    this.postBg = new Post(this.$gl, { targetOnly: true });
    // `targetOnly: true` prevents post from rendering to canvas
    this.postBloom = new Post(this.$gl, { dpr: 0.25, targetOnly: true });

    // Create uniforms for passes
    this.resolution = { value: new Vec2() };
    this.bloomResolution = { value: new Vec2() };

    //
    this.initPasses();

    //

    this.bg = new Bg({ options: { parent: this.scene } });
    this.wall = new Wall({ options: { parent: this.scene } });
  },

  hooks: {
    'set:size'({ width, height, pr }) {
      this.postComposite.resize();
      this.postBg.resize();
      this.postBloom.resize();
      this.resolution.value.set(width, height);
      this.bloomResolution.value.set(
        this.postBloom.options.width,
        this.postBloom.options.height
      );

      this.camera.perspective({ aspect: width / height });
    },
    'set:cameraXY'({ x, y }) {
      // this.camera.position.set(x * 0.0025, y * 0.002, 7);
      // this.camera.lookAt(new Vec3(0, 0, 0));
      // this.camera.lookAt(new Vec3(x * 0.001, y * 0.001, 0));
    },
  },

  methods: {
    initPasses() {
      // Add Bright pass - filter the scene to only the bright parts we want to blur
      const brightPass = this.postBloom.addPass({
        fragment: brightPassFragment,
        uniforms: {
          uThreshold: { value: 0.75 },
        },
      });
      // Add gaussian blur passes
      const horizontalPass = this.postBloom.addPass({
        fragment: blurFragment,
        uniforms: {
          uResolution: this.bloomResolution,
          uDirection: { value: new Vec2(2, 0) },
        },
      });
      const verticalPass = this.postBloom.addPass({
        fragment: blurFragment,
        uniforms: {
          uResolution: this.bloomResolution,
          uDirection: { value: new Vec2(0, 2) },
        },
      });
      // Re-add the gaussian blur passes several times to the array to get smoother results
      for (let i = 0; i < 5; i++) {
        this.postBloom.passes.push(horizontalPass, verticalPass);
      }

      // Add final composite pass
      this.compositePass = this.postComposite.addPass({
        fragment: compositeFragment,
        uniforms: {
          uResolution: this.resolution,
          tBloom: this.postBloom.uniform,
          uBloomStrength: { value: this.$darkGrading ? 3 : 1.5 },
          uFade: { value: 1 },
        },
      });

      IMOG.inject('compositePass', this.compositePass);
    },
    render() {
      this.$gl.clearColor(0 / 255, 10 / 255, 80 / 255, 1);

      // Disable compositePass pass, so this post will just render the scene for now
      this.compositePass.enabled = false;
      // `targetOnly` prevents post from rendering to the canvas
      this.postComposite.targetOnly = true;
      // This renders the scene to postComposite.uniform.value

      // (1) --> Render BG and Wall into bgTarget
      // this.bg.bg.visible = true;
      // this.wall.wall.visible = true;
      // this.postBg.render({
      //   scene: this.scene,
      //   camera: this.camera,
      //   targetOnly: true,
      // });

      // (2) --> Blur BG and Wall
      // this.postBloom.passes[0].enabled = false;
      // this.postBloom.render({
      //   texture: this.postBg.uniform.value,
      //   targetOnly: true,
      // });

      // (3) --> Render full scene (TODO=> reuse postBG )
      this.bg.bg.visible = true;
      this.wall.wall.visible = true;
      this.postComposite.render({ scene: this.scene, camera: this.camera });

      // (4) --> Render bloom
      // This render the bloom effect's bright and blur passes to postBloom.fbo.read
      // Passing in a `texture` argument avoids the post initially rendering the scene
      this.postBloom.passes[0].enabled = true;
      this.postBloom.render({
        texture: this.postComposite.uniform.value,
        targetOnly: true,
      });

      // (5) --> Composite
      // Re-enable composite pass
      this.compositePass.enabled = true;
      // Allow post to render to canvas upon its last pass
      this.postComposite.targetOnly = false;
      // This renders to canvas, compositing the bloom pass on top
      // pass back in its previous render of the scene to avoid re-rendering
      this.postComposite.render({ texture: this.postComposite.uniform.value });
    },
  },
});

const brightPassFragment = `precision highp float;
    uniform sampler2D tMap;
    uniform float uThreshold;
    varying vec2 vUv;
    void main() {
        vec4 tex = texture2D(tMap, vUv);
        vec4 bright = tex * step(uThreshold, length(tex.rgb) / 1.73205);
        gl_FragColor = bright;
    }
`;

const blurFragment = `precision highp float;
    // https://github.com/Jam3/glsl-fast-gaussian-blur/blob/master/5.glsl
    vec4 blur5(sampler2D image, vec2 uv, vec2 resolution, vec2 direction) {
        vec4 color = vec4(0.0);
        vec2 off1 = vec2(1.3333333333333333) * direction;
        color += texture2D(image, uv) * 0.29411764705882354;
        color += texture2D(image, uv + (off1 / resolution)) * 0.35294117647058826;
        color += texture2D(image, uv - (off1 / resolution)) * 0.35294117647058826;
        return color;
    }
    // https://github.com/Jam3/glsl-fast-gaussian-blur/blob/master/9.glsl
    vec4 blur9(sampler2D image, vec2 uv, vec2 resolution, vec2 direction) {
        vec4 color = vec4(0.0);
        vec2 off1 = vec2(1.3846153846) * direction;
        vec2 off2 = vec2(3.2307692308) * direction;
        color += texture2D(image, uv) * 0.2270270270;
        color += texture2D(image, uv + (off1 / resolution)) * 0.3162162162;
        color += texture2D(image, uv - (off1 / resolution)) * 0.3162162162;
        color += texture2D(image, uv + (off2 / resolution)) * 0.0702702703;
        color += texture2D(image, uv - (off2 / resolution)) * 0.0702702703;
        return color;
    }
    uniform sampler2D tMap;
    uniform vec2 uDirection;
    uniform vec2 uResolution;
    varying vec2 vUv;
    void main() {
        // Swap with blur9 for higher quality
        // gl_FragColor = blur9(tMap, vUv, uResolution, uDirection);
        gl_FragColor = blur5(tMap, vUv, uResolution, uDirection);
    }
`;

const compositeFragment = `precision highp float;
    uniform sampler2D tMap;
    uniform sampler2D tBloom;
    uniform vec2 uResolution;
    uniform float uBloomStrength;
    uniform float uFade;
    varying vec2 vUv;
    void main() {
        gl_FragColor = uFade * (texture2D(tMap, vUv) + texture2D(tBloom, vUv) * uBloomStrength);
        // gl_FragColor = texture2D(tMap, vUv);
        // gl_FragColor = texture2D(tBloom, vUv) * uBloomStrength;
    }
`;
