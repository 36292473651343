import { Program } from 'ogl';

export default gl => {
  return new Program(gl, {
    vertex: /* glsl */ `
      precision highp float;
      attribute vec2 position;
      attribute vec2 uv;
      varying vec2 vUv;
      void main () {
          vUv = uv;
          gl_Position = vec4(position, 0, 1);
      }
    `,
    fragment: /* glsl */ `
      precision mediump float;
      precision mediump sampler2D;
      uniform sampler2D tFluidSim;
      uniform sampler2D tBlur;
      varying highp vec2 vUv;

      vec3 rgb2hsv(vec3 c){
          vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
          vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
          vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

          float d = q.x - min(q.w, q.y);
          float e = 1.0e-10;
          return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
      }

      vec3 hsv2rgb(vec3 c){
          vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
          vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
          return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
      }

      void main () {
          vec4 fluid = texture2D(tFluidSim, vUv);
          vec4 blur = texture2D(tBlur, vUv);

          vec3 result = fluid.rgb + blur.rgb;
          vec3 hsl = rgb2hsv(result);
          // hsl.x += 0.7;
          // hsl.y += 0.2;
          // hsl.z -= 0.1;

          // hsl.x -= 0.03;
          // hsl.y -= 0.2;
          hsl.z -= 0.3;


          result = hsv2rgb(hsl);
          gl_FragColor = vec4(result, 1.0);
      }
    `,
    uniforms: {
      tFluidSim: { value: null },
      tBlur: { value: null }
    },
    depthTest: false,
    depthWrite: false
  });
};
