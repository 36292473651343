import { Program } from 'ogl';

export default (gl) => {
  return new Program(gl, {
    vertex: /* glsl */ `
      precision highp float;
      attribute vec2 position;
      attribute vec2 uv;
      varying vec2 vUv;
      void main () {
          vUv = uv;
          gl_Position = vec4(position, 0, 1);
      }
    `,
    fragment: /* glsl */ `
      precision mediump float;
      precision mediump sampler2D;
      uniform sampler2D tFluidSim;
      varying highp vec2 vUv;
      void main () {
          vec4 fluid = texture2D(tFluidSim, vUv);
          gl_FragColor = fluid;
          // gl_FragColor = vec4(vec3(step(0.5, fluid.b)), 1.0);
      }
    `,
    uniforms: {
      tFluidSim: { value: null },
    },
    depthTest: false,
    depthWrite: false,
  });
};
