import IMOG from '~/lib/imog';
import {
  RenderTarget,
  Mesh,
  Geometry,
  Camera,
  Program,
  Color,
  Plane,
  TextureLoader,
  Vec3,
  Vec2,
  Transform,
} from 'ogl';

import useWindowSize from '~/lib/imog/use/windowSize';

export default IMOG.Component('Wall', {
  options: {
    aprent: null,
  },

  props() {
    return {
      windowSize: useWindowSize(),
      size: (props) => ({
        width: props.windowSize.width,
        height: props.windowSize.height,
      }),
      active: true,
    };
  },

  setup({ options }) {
    const char0Texture = TextureLoader.load(this.$gl, {
      src: '/texture/char-0.png',
    });
    char0Texture.minFilter = this.$gl.NEAREST;
    const char1Texture = TextureLoader.load(this.$gl, {
      src: '/texture/char-1.png',
    });
    char1Texture.minFilter = this.$gl.NEAREST;

    const gradientTexture = TextureLoader.load(this.$gl, {
      src: '/texture/gradient.png',
    });

    this.wall = new Mesh(this.$gl, {
      geometry: new Plane(this.$gl, {
        width: 1,
        height: 1,
        widthSegments: 100,
        heightSegments: 100,
      }),
      program: new Program(this.$gl, {
        vertex: `attribute vec3 position;
           attribute vec3 normal;
           uniform mat4 modelViewMatrix;
           uniform mat4 projectionMatrix;
           attribute vec2 uv;
           varying vec2 vUv;
           void main() {
               vUv = uv;
               vec3 p = position;
               // p.z -= 2.0 * cos(p.x * 3.1416 * 0.5);
               // p.xy *= vec2(9.0, 5.0) * 1.5;
               gl_Position = projectionMatrix * modelViewMatrix * vec4(p, 1.0);
           }
        `,
        fragment: `precision highp float;
          precision mediump sampler2D;
          // varying vec3 vNormal;
          uniform sampler2D tFluidSim;
          uniform sampler2D t0;
          uniform sampler2D t1;
          uniform sampler2D tGradient;
          uniform float uTime;
          uniform vec2 uScreenSize;
          varying highp vec2 vUv;

          float rand(float n){return fract(sin(n * uTime) * 43758.5453123);}

          vec3 pal( in float t, in vec3 a, in vec3 b, in vec3 c, in vec3 d ){
              return a + b*cos( 6.28318*(c*t+d) );
          }

          vec3 rgb2hsv(vec3 c){
              vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
              vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
              vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

              float d = q.x - min(q.w, q.y);
              float e = 1.0e-10;
              return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
          }

          vec3 hsv2rgb(vec3 c){
              vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
              vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
              return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
          }


          float sdBox( in vec2 p, in vec2 b ){
              vec2 d = abs(p)-b;
              return length(max(d,0.0)) + min(max(d.x,d.y),0.0);
          }

          void main() {
              // vec3 normal = normalize(vNormal);
              // float lighting = dot(normal, normalize(vec3(-0.3, 0.8, 0.6)));
              vec2 screenUv = gl_FragCoord.xy / uScreenSize;
              vec2 res = vec2(100.0, 80.0) * 0.0007 * uScreenSize;
              float vLine = smoothstep(0.1, 0.08, fract(vUv.x * res.x + 0.05));
              float hLine = smoothstep(0.1, 0.08, fract(vUv.y * res.y + 0.05));
              float lines = clamp(vLine + hLine, 0.0, 1.0);
              vec2 cellUv = fract(vUv * res);
              vec2 cellI = floor(vUv * res);
              vec2 rr  = vec2(rand(cellI.x * 0.01), rand(cellI.y * 1291.912 + cellI.x + 625.471637));
              vec4 fluid = texture2D(tFluidSim, cellI / res);

              float char = fract((cellI.x + cellI.y * 3.183) * 193.941 + fluid.r * rr.x * 13.0) > 0.5 ? texture2D(t1, cellUv).r : texture2D(t0, cellUv).r;
              float lightOn = step(0.5, fluid.r);
              float shade = (rr.x + rr.y * 2.0) / 2.0;

              vec3 darkGreen = vec3(5. / 255., 18. / 255., 104. / 255.);
              vec3 lightGreen = vec3(0.059,0.969,0.988);

              vec3 result = darkGreen + vec3(0.008,0.09,0.624) * 0.33;
              // result = mix(result, result - 0.1, shade);
              // result = mix(result, result + lightGreen * 0.4, pow(shade, 2.0) * lightOn);
              result = mix(result, result + lightGreen * 0.25, lightOn);
              // result -= 0.05 * smoothstep(1.0, 0.5, vUv.y);
              // result += 0.1 * smoothstep(0.5, 0.0, vUv.y);
              // result = mix(result, result + 0.1, lines);
              // result = mix(result, lightGreen, char * shade * 0.4);
              // result = mix(result, result + 0.4, char * lightOn);


              gl_FragColor.rgb = clamp(result, 0.0, 1.0);
              // gl_FragColor.rgb = clamp(result, 0.0, 1.0);

              gl_FragColor.a = mix(0.5, 1.0, lightOn);

              // gl_FragColor = vec4(screenUv, 1.0, 1.0);

              ${this.$darkGrading
            ? `// gl_FragColor.rgb = vec3((gl_FragColor.r + gl_FragColor.g + gl_FragColor.b) / 3.0 * 0.5 );

                      vec3 fHSV = rgb2hsv(gl_FragColor.rgb * 0.75);
                      fHSV.r -= 0.15;
                      fHSV.g -= 0.5;
                      fHSV.b -= 0.32;
                      gl_FragColor.rgb = hsv2rgb(fHSV);

                      // vec3 color = pal(floor(fluid.b * 16.0) / 16.0, vec3(0.8,0.5,0.4),vec3(0.2,0.4,0.2),vec3(2.0,1.0,1.0),vec3(0.5,0.15,0.2) );
                      vec3 color = texture2D(tGradient, vec2(fluid.b, 1.0)).rgb;
                      // vec3 hsl = rgb2hsv(color);
                      // hsl.x += 0.3;
                      // color = hsv2rgb(hsl);
                      if (lightOn >= 0.5) gl_FragColor.rgb *= color * 4.0;
                      // if (lightOn >= 0.5) gl_FragColor.a = 1.0;
                  `
            : ``
          }

              // gl_FragColor.a = 1.0;

              // gl_FragColor.rgb = vec3();

          }
        `,
        uniforms: {
          tFluidSim: { value: null },
          t0: { value: char0Texture },
          t1: { value: char1Texture },
          tGradient: { value: gradientTexture },
          uScreenSize: { value: new Vec2(100, 100) },
          uTime: { value: 100 },
        },
        transparent: true,
      }),
    });
    this.wall.setParent(options.parent);
  },

  hooks: {
    'set:size'({ width, height }) {
      this.wall.program.uniforms.uScreenSize.value.set(width, height);
      const distance =
        1 + this.$sceneCamera.position.distance(this.wall.position);
      const fov = (this.$sceneCamera.fov * Math.PI) / 180; // convert vertical fov to radians
      const h = 2 * Math.tan(fov / 2) * distance; // visible height
      const w = h * (width / height);
      this.wall.scale.set(w, h, 1);
      // return { width: w, height: h, factor: width / w, distance };
    },
    'while:active'() {
      // this.wall.program.uniforms.uTime.value =1+
      // Math.floor(performance.now() * 0.01) % 1000;
    },
  },
});
