import IMOG from '~/lib/imog';
import query from 'simple-query-string';
const config = query.parse(location.search);

import Post from '~/component/Post/index';
import FluidSim from '~/component/FluidSim/index';
import Scene from '~/component/Scene/index';

export default IMOG.Component('WallCanvas', {
  setup({ options }) {
    IMOG.inject('darkGrading', true);
    this.post = new Post();
    this.fluidSim = new FluidSim();
    this.scene = new Scene();
    options.handleReady();
  },

  render() {
    this.fluidSim.render();
    this.scene.wall.wall.program.uniforms.tFluidSim.value = this.fluidSim.readTexture;

    this.scene.render();
  },
});
