import _ from 'underscore';
import IMOG from '~/lib/imog';
import {
  Geometry,
  Post,
  Program,
  RenderTarget,
  Mesh,
  Triangle,
  Vec2,
  Color,
} from 'ogl';
import * as OGLhelpers from '~/lib/ogl/helpers';
import gsap from 'gsap';

import * as Shaders from './shaders';

const triangle = _.memoize(
  (gl) =>
    new Geometry(gl, {
      position: { size: 2, data: new Float32Array([-1, -1, 3, -1, -1, 3]) },
      uv: { size: 2, data: new Float32Array([0, 0, 2, 0, 0, 2]) },
    })
);

const palette = (
  t,
  a = [0.5, 0.5, 0.5],
  b = [0.5, 0.5, 0.5],
  c = [1, 1, 0.5],
  d = [0.8, 0.9, 0.3]
) => {
  return [
    a[0] + b[0] * Math.cos(Math.PI * 2 * (c[0] * t + d[0])),
    a[1] + b[1] * Math.cos(Math.PI * 2 * (c[1] * t + d[1])),
    a[2] + b[2] * Math.cos(Math.PI * 2 * (c[2] * t + d[2])),
  ];
};

const densityDissipation = 0.995;
const velocityDissipation = 0.98;
const pressureDissipation = 0.8;
export default IMOG.Component('FluidSim', {
  options: {
    simRes: 128,
    dyeRes: 512,
    gui: false,
  },

  props() {
    return {
      iterations: 3,
      densityDissipation,
      velocityDissipation,
      pressureDissipation,
      curlStrength: 0,
      radius: 0.7,
    };
  },

  setup({ options }) {
    if (options.gui) this.setupGUI();

    const { simRes, dyeRes } = options;

    // Get supported formats and types for FBOs
    let supportLinearFiltering = this.$gl.renderer.extensions[
      `OES_texture_${this.$gl.renderer.isWebgl2 ? `` : `half_`}float_linear`
    ];
    const halfFloat = this.$gl.renderer.isWebgl2
      ? this.$gl.HALF_FLOAT
      : this.$gl.renderer.extensions['OES_texture_half_float'].HALF_FLOAT_OES;

    const filtering = supportLinearFiltering
      ? this.$gl.LINEAR
      : this.$gl.NEAREST;
    let rgba, rg, r;

    if (this.$gl.renderer.isWebgl2) {
      rgba = OGLhelpers.getSupportedFormat(
        this.$gl,
        this.$gl.RGBA16F,
        this.$gl.RGBA,
        halfFloat
      );
      rg = OGLhelpers.getSupportedFormat(
        this.$gl,
        this.$gl.RG16F,
        this.$gl.RG,
        halfFloat
      );
      r = OGLhelpers.getSupportedFormat(
        this.$gl,
        this.$gl.R16F,
        this.$gl.RED,
        halfFloat
      );
    } else {
      rgba = OGLhelpers.getSupportedFormat(
        this.$gl,
        this.$gl.RGBA,
        this.$gl.RGBA,
        halfFloat
      );
      rg = rgba;
      r = rgba;
    }

    this.texelSize = { value: new Vec2(1 / simRes) };

    const density = OGLhelpers.createDoubleFBO(this.$gl, {
      width: dyeRes,
      height: dyeRes,
      type: halfFloat,
      format: rgba.format,
      internalFormat: rgba.internalFormat,
      minFilter: filtering,
      depth: false,
    });
    this.density = density;

    const velocity = OGLhelpers.createDoubleFBO(this.$gl, {
      width: simRes,
      height: simRes,
      type: halfFloat,
      format: rg.format,
      internalFormat: rg.internalFormat,
      minFilter: filtering,
      depth: false,
    });
    this.velocity = velocity;

    const pressure = OGLhelpers.createDoubleFBO(this.$gl, {
      width: simRes,
      height: simRes,
      type: halfFloat,
      format: r.format,
      internalFormat: r.internalFormat,
      minFilter: this.$gl.NEAREST,
      depth: false,
    });
    this.pressure = pressure;

    const divergence = new RenderTarget(this.$gl, {
      width: simRes,
      height: simRes,
      type: halfFloat,
      format: r.format,
      internalFormat: r.internalFormat,
      minFilter: this.$gl.NEAREST,
      depth: false,
    });
    this.divergence = divergence;

    const curl = new RenderTarget(this.$gl, {
      width: simRes,
      height: simRes,
      type: halfFloat,
      format: r.format,
      internalFormat: r.internalFormat,
      minFilter: this.$gl.NEAREST,
      depth: false,
    });
    this.curl = curl;

    // Create fluid simulation programs
    const clearProgram = new Mesh(this.$gl, {
      geometry: triangle(this.$gl),
      program: new Program(this.$gl, {
        vertex: Shaders.baseVertex,
        fragment: Shaders.clearShader,
        uniforms: {
          texelSize: this.texelSize,
          uTexture: { value: null },
          value: { value: this.props.pressureDissipation },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });
    this.clearProgram = clearProgram;

    const clearRadiusProgram = new Mesh(this.$gl, {
      geometry: triangle(this.$gl),
      program: new Program(this.$gl, {
        vertex: Shaders.baseVertex,
        fragment: Shaders.clearRadiusShader,
        uniforms: {
          texelSize: this.texelSize,
          uTexture: { value: null },
          radius: { value: 0 },
          aspectRatio: { value: 1 },
          point: { value: new Vec2(0.5, 0.5) },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });
    this.clearRadiusProgram = clearRadiusProgram;

    const splatProgram = new Mesh(this.$gl, {
      geometry: triangle(this.$gl),
      program: new Program(this.$gl, {
        vertex: Shaders.baseVertex,
        fragment: Shaders.splatShader,
        uniforms: {
          texelSize: this.texelSize,
          uTarget: { value: null },
          aspectRatio: { value: 1 },
          color: { value: new Color() },
          point: { value: new Vec2() },
          radius: { value: 1 },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });
    this.splatProgram = splatProgram;

    const splatColorProgram = new Mesh(this.$gl, {
      geometry: triangle(this.$gl),
      program: new Program(this.$gl, {
        vertex: Shaders.baseVertex,
        fragment: Shaders.splatColorShader,
        uniforms: {
          texelSize: this.texelSize,
          uTarget: { value: null },
          aspectRatio: { value: 1 },
          color: { value: new Color() },
          point: { value: new Vec2() },
          radius: { value: 1 },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });
    this.splatColorProgram = splatColorProgram;

    const advectionProgram = new Mesh(this.$gl, {
      geometry: triangle(this.$gl),
      program: new Program(this.$gl, {
        vertex: Shaders.baseVertex,
        fragment: supportLinearFiltering
          ? Shaders.advectionShader
          : Shaders.advectionManualFilteringShader,
        uniforms: {
          texelSize: this.texelSize,
          dyeTexelSize: { value: new Vec2(1 / dyeRes, 1 / dyeRes) },
          uVelocity: { value: null },
          uSource: { value: null },
          dt: { value: 0.016 },
          dissipation: { value: 1.0 },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });
    this.advectionProgram = advectionProgram;

    const divergenceProgram = new Mesh(this.$gl, {
      geometry: triangle(this.$gl),
      program: new Program(this.$gl, {
        vertex: Shaders.baseVertex,
        fragment: Shaders.divergenceShader,
        uniforms: {
          texelSize: this.texelSize,
          uVelocity: { value: null },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });
    this.divergenceProgram = divergenceProgram;

    const curlProgram = new Mesh(this.$gl, {
      geometry: triangle(this.$gl),
      program: new Program(this.$gl, {
        vertex: Shaders.baseVertex,
        fragment: Shaders.curlShader,
        uniforms: {
          texelSize: this.texelSize,
          uVelocity: { value: null },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });
    this.curlProgram = curlProgram;

    const vorticityProgram = new Mesh(this.$gl, {
      geometry: triangle(this.$gl),
      program: new Program(this.$gl, {
        vertex: Shaders.baseVertex,
        fragment: Shaders.vorticityShader,
        uniforms: {
          texelSize: this.texelSize,
          uVelocity: { value: null },
          uCurl: { value: null },
          curl: { value: this.props.curlStrength },
          dt: { value: 0.016 },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });
    this.vorticityProgram = vorticityProgram;

    const pressureProgram = new Mesh(this.$gl, {
      geometry: triangle(this.$gl),
      program: new Program(this.$gl, {
        vertex: Shaders.baseVertex,
        fragment: Shaders.pressureShader,
        uniforms: {
          texelSize: this.texelSize,
          uPressure: { value: null },
          uDivergence: { value: null },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });
    this.pressureProgram = pressureProgram;

    const gradienSubtractProgram = new Mesh(this.$gl, {
      geometry: triangle(this.$gl),
      program: new Program(this.$gl, {
        vertex: Shaders.baseVertex,
        fragment: Shaders.gradientSubtractShader,
        uniforms: {
          texelSize: this.texelSize,
          uPressure: { value: null },
          uVelocity: { value: null },
        },
        depthTest: false,
        depthWrite: false,
      }),
    });
    this.gradienSubtractProgram = gradienSubtractProgram;

    this.splats = [];
    this.lastMouse = new Vec2();
    this.colorT = 0;

    // Create handlers to get mouse position and velocity
    const isTouchCapable = 'ontouchstart' in window;
    if (isTouchCapable) {
      window.addEventListener('touchstart', this.updateMouse, false);
      window.addEventListener('touchmove', this.updateMouse, false);
    } else {
      window.addEventListener('mousemove', this.updateMouse, false);
      window.addEventListener('click', this.shock, false);
    }
  },

  methods: {
    updateMouse(e) {
      if (e.changedTouches && e.changedTouches.length) {
        e.x = e.changedTouches[0].pageX;
        e.y = e.changedTouches[0].pageY;
      }
      if (e.x === undefined) {
        e.x = e.pageX;
        e.y = e.pageY;
      }

      if (!this.lastMouse.isInit) {
        this.lastMouse.isInit = true;

        // First input
        this.lastMouse.set(e.x, e.y);
      }

      const deltaX = e.x - this.lastMouse.x;
      const deltaY = e.y - this.lastMouse.y;

      this.lastMouse.set(e.x, e.y);

      // Add if the mouse is moving
      if (Math.abs(deltaX) || Math.abs(deltaY)) {
        this.splats.push({
          // Get mouse value in 0 to 1 range, with y flipped
          x: e.x / this.$gl.renderer.width,
          y: 1.0 - e.y / this.$gl.renderer.height,
          dx: deltaX * 5.0,
          dy: deltaY * -5.0,
        });
      }
    },
    shock(e) {
      if (this.shocking) return;
      this.shocking = true;
      const x = e.x / this.$gl.renderer.width;
      const y = 1.0 - e.y / this.$gl.renderer.height;

      const t = { r: 0 };

      const d = 0.7;

      gsap.to(this.$compositePass.uniforms.uFade, {
        value: 1.5,
        duration: 0.2 * d,
        ease: 'Sine.easeIn',
        onComplete: () => {
          gsap.to(this.$compositePass.uniforms.uFade, {
            value: 1,
            duration: 1 * d,
            ease: 'Power2.easeOut',
          });
        },
      });

      Object.assign(this.props, {
        densityDissipation: 1,
        velocityDissipation: 1,
        pressureDissipation: 1,
      });
      gsap.to(t, {
        r: 60,
        duration: 1 * d,
        ease: 'Power2.easeOut',

        onUpdate: () => {
          this.colorT += 0.1;

          this.splatProgram.program.uniforms.uTarget.value = this.velocity.read.texture;

          this.clearRadiusProgram.program.uniforms.aspectRatio.value =
            this.$gl.renderer.width / this.$gl.renderer.height;
          this.splatProgram.program.uniforms.aspectRatio.value =
            this.$gl.renderer.width / this.$gl.renderer.height;
          this.splatProgram.program.uniforms.point.value.set(x, y);
          // this.splatProgram.program.uniforms.color.value.set(dx, dy, 1.0);
          // this.splatProgram.program.uniforms.radius.value = t.r / 100.0;

          this.$gl.renderer.render({
            scene: this.splatProgram,
            target: this.velocity.write,
            sort: false,
            update: false,
          });
          this.velocity.swap();

          this.splatColorProgram.program.uniforms.uTarget.value = this.density.read.texture;
          this.splatColorProgram.program.uniforms.color.value.set(
            1,
            0,
            Math.sin(this.colorT) * 0.5 + 0.5
          );
          this.splatColorProgram.program.uniforms.aspectRatio.value =
            this.$gl.renderer.width / this.$gl.renderer.height;
          this.splatColorProgram.program.uniforms.point.value.set(x, y);
          this.splatColorProgram.program.uniforms.radius.value = t.r / 100.0;

          this.$gl.renderer.render({
            scene: this.splatColorProgram,
            target: this.density.write,
            sort: false,
            update: false,
          });
          this.density.swap();
        },
      });
      this.clearRadiusProgram.program.uniforms.point.value.set(x, y);
      gsap.to(this.clearRadiusProgram.program.uniforms.radius, {
        value: 60 / 100,
        duration: 1.4 * d,
        ease: 'Power1.easeOut',
        delay: 0.15 * d,
        onComplete: () => {
          this.clearRadiusProgram.program.uniforms.radius.value = 0;
          this.shocking = false;
          Object.assign(this.props, {
            densityDissipation,
            velocityDissipation,
            pressureDissipation,
          });
        },
      });
    },
    splat({ x, y, dx, dy }) {
      this.splatProgram.program.uniforms.uTarget.value = this.velocity.read.texture;
      this.splatProgram.program.uniforms.aspectRatio.value =
        this.$gl.renderer.width / this.$gl.renderer.height;
      this.splatProgram.program.uniforms.point.value.set(x, y);
      this.splatProgram.program.uniforms.color.value.set(dx, dy, 1.0);
      this.splatProgram.program.uniforms.radius.value =
        this.props.radius / 100.0;

      this.$gl.renderer.render({
        scene: this.splatProgram,
        target: this.velocity.write,
        sort: false,
        update: false,
      });
      this.velocity.swap();

      this.splatColorProgram.program.uniforms.uTarget.value = this.density.read.texture;
      this.colorT += 0.05;
      // const color = palette(this.colorT);
      // this.splatColorProgram.program.uniforms.color.value.set(
      //   Math.max(color[0], 0.1),
      //   Math.max(color[1], 0.1),
      //   Math.max(color[2], 0.1)
      // );
      this.splatColorProgram.program.uniforms.color.value.set(
        1,
        0,
        Math.sin(this.colorT) * 0.5 + 0.5
      );
      this.splatColorProgram.program.uniforms.aspectRatio.value =
        this.$gl.renderer.width / this.$gl.renderer.height;
      this.splatColorProgram.program.uniforms.point.value.set(x, y);
      this.splatColorProgram.program.uniforms.radius.value =
        this.props.radius / 100.0;

      this.$gl.renderer.render({
        scene: this.splatColorProgram,
        target: this.density.write,
        sort: false,
        update: false,
      });
      this.density.swap();
    },
    render() {
      // Perform all of the fluid simulation renders
      // No need to clear during sim, saving a number of GL calls.
      this.$gl.renderer.autoClear = false;
      this.vorticityProgram.program.uniforms.curl.value = this.props.curlStrength;

      // Render all of the inputs since last frame
      for (let i = this.splats.length - 1; i >= 0; i--) {
        this.splat(this.splats.splice(i, 1)[0]);
      }

      this.curlProgram.program.uniforms.uVelocity.value = this.velocity.read.texture;

      this.$gl.renderer.render({
        scene: this.curlProgram,
        target: this.curl,
        sort: false,
        update: false,
      });

      this.vorticityProgram.program.uniforms.uVelocity.value = this.velocity.read.texture;
      this.vorticityProgram.program.uniforms.uCurl.value = this.curl.texture;

      this.$gl.renderer.render({
        scene: this.vorticityProgram,
        target: this.velocity.write,
        sort: false,
        update: false,
      });
      this.velocity.swap();

      this.divergenceProgram.program.uniforms.uVelocity.value = this.velocity.read.texture;

      this.$gl.renderer.render({
        scene: this.divergenceProgram,
        target: this.divergence,
        sort: false,
        update: false,
      });

      this.clearRadiusProgram.program.uniforms.uTexture.value = this.density.read.texture;

      this.$gl.renderer.render({
        scene: this.clearRadiusProgram,
        target: this.density.write,
        sort: false,
        update: false,
      });
      this.density.swap();

      this.clearProgram.program.uniforms.uTexture.value = this.pressure.read.texture;
      this.clearProgram.program.uniforms.value.value = this.props.pressureDissipation;

      this.$gl.renderer.render({
        scene: this.clearProgram,
        target: this.pressure.write,
        sort: false,
        update: false,
      });
      this.pressure.swap();

      this.pressureProgram.program.uniforms.uDivergence.value = this.divergence.texture;

      for (let i = 0; i < this.props.iterations; i++) {
        this.pressureProgram.program.uniforms.uPressure.value = this.pressure.read.texture;

        this.$gl.renderer.render({
          scene: this.pressureProgram,
          target: this.pressure.write,
          sort: false,
          update: false,
        });
        this.pressure.swap();
      }

      this.gradienSubtractProgram.program.uniforms.uPressure.value = this.pressure.read.texture;
      this.gradienSubtractProgram.program.uniforms.uVelocity.value = this.velocity.read.texture;

      this.$gl.renderer.render({
        scene: this.gradienSubtractProgram,
        target: this.velocity.write,
        sort: false,
        update: false,
      });
      this.velocity.swap();

      this.advectionProgram.program.uniforms.dyeTexelSize.value.set(
        1 / this.options.simRes
      );
      this.advectionProgram.program.uniforms.uVelocity.value = this.velocity.read.texture;
      this.advectionProgram.program.uniforms.uSource.value = this.velocity.read.texture;
      this.advectionProgram.program.uniforms.dissipation.value = this.props.velocityDissipation;

      this.$gl.renderer.render({
        scene: this.advectionProgram,
        target: this.velocity.write,
        sort: false,
        update: false,
      });
      this.velocity.swap();

      this.advectionProgram.program.uniforms.dyeTexelSize.value.set(
        1 / this.options.dyeRes
      );
      this.advectionProgram.program.uniforms.uVelocity.value = this.velocity.read.texture;
      this.advectionProgram.program.uniforms.uSource.value = this.density.read.texture;
      this.advectionProgram.program.uniforms.dissipation.value = this.props.densityDissipation;

      this.$gl.renderer.render({
        scene: this.advectionProgram,
        target: this.density.write,
        sort: false,
        update: false,
      });
      this.density.swap();

      // Set clear back to default
      this.$gl.renderer.autoClear = true;

      // Update post pass uniform with the simulation output
      // pass.uniforms.tFluid.value =
      this.readTexture = this.density.read.texture;

      // mesh.rotation.y -= 0.0025;
      // mesh.rotation.x -= 0.005;

      // pass.uniforms.uTime.value = t * 0.001;

      // Replace Renderer.render with post.render. Use the same arguments.
      // post.render({ scene: mesh, camera });
    },
    async setupGUI() {
      await require('~/lib/gui');
      const folder = this.$gui.addFolder(`FluidSim`);
      folder.open();
      folder.add(this.props, 'iterations', 1, 10, 1);
      folder.add(this.props, 'densityDissipation', 0.95, 1, 0.001);
      folder.add(this.props, 'velocityDissipation', 0.8, 1, 0.01);
      folder.add(this.props, 'pressureDissipation', 0.8, 1, 0.01);
      folder.add(this.props, 'curlStrength', 0, 30);
      folder.add(this.props, 'radius', 0, 5, 0.05);
    },
  },
});
