import 'regenerator-runtime/runtime';
import { map } from '~/lib/math';

import '~/style.scss';
import Canvas from '~/component/Canvas/';

window.canvas = new Canvas({
  options: {
    useMockData: true,
    handleReady: () => {
      // document.body.querySelector('.container').style.display = 'block';
      // document.body.querySelector('.container.glow').style.display = 'block';
    },
  },
});

const resize = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)

  document.body.style.setProperty(
    '--scale',
    map(innerWidth, 1200, 2000, 1, 1.5, true)
  );
};
resize();
window.addEventListener('resize', resize);
