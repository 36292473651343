import { Program, Texture, Vec2 } from 'ogl';

function getBayerTexture(gl) {
  var canvas = document.createElement('canvas');
  canvas.width = 4;
  canvas.height = 4;

  var context = canvas.getContext('2d');
  var image = context.getImageData(0, 0, canvas.width, canvas.height);

  var data = [
    [1, 9, 3, 11],
    [13, 5, 15, 7],
    [4, 12, 2, 10],
    [16, 8, 14, 6],
  ];

  var x = 0,
    y = -1;

  for (var i = 0, j = 0, l = image.data.length; i < l; i += 4, j++) {
    x = j % canvas.width;
    y = x == 0 ? y + 1 : y;

    var norm = Math.floor((255.0 * data[y][x]) / 17.0);

    image.data[i] = norm;
    image.data[i + 1] = norm;
    image.data[i + 2] = norm;
    image.data[i + 3] = norm;
  }

  context.putImageData(image, 0, 0);

  var image = new Image();
  image.src = canvas.toDataURL();

  var texture = new Texture(gl, {
    generateMipmaps: false,
    magFilter: gl.NEAREST,
    minFilter: gl.NEAREST,
  });

  texture.image = image;

  return texture;
}

export default (gl) => {
  return new Program(gl, {
    vertex: /* glsl */ `
      precision highp float;
      attribute vec2 position;
      attribute vec2 uv;
      varying vec2 vUv;
      void main () {
          vUv = uv;
          gl_Position = vec4(position, 0, 1);
      }
    `,
    fragment: /* glsl */ `
      precision mediump float;
      precision mediump sampler2D;
      uniform sampler2D tFluidSim;
      uniform sampler2D tBayer;
      uniform vec2 uResolution;
      varying highp vec2 vUv;

      float find_closest(float x, float y, float c0) {
          float limit = texture2D(tBayer, vec2(x / 4.0, y / 4.0)).r;

          if(c0 < limit)
              return 0.0;
          else
              return 1.0;
      }

      vec3 pal( in float t, in vec3 a, in vec3 b, in vec3 c, in vec3 d ){
          return a + b*cos( 6.28318*(c*t+d) );
      }

      vec3 rgb2hsv(vec3 c){
          vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
          vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
          vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

          float d = q.x - min(q.w, q.y);
          float e = 1.0e-10;
          return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
      }

      vec3 hsv2rgb(vec3 c){
          vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
          vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
          return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
      }


      void main () {
          vec2 uvPixel = floor(vUv * uResolution) / uResolution;
          vec4 fluid = texture2D(tFluidSim, uvPixel);
          // vec4 lum = vec4(0.5, 0.5, 0.5, 0);
          // float grayscale = dot(fluid, lum);
          vec2 xy = vUv;
          float x = mod(xy.x * uResolution.x, 4.0);
          float y = mod(xy.y * uResolution.y, 4.0);
          float final = find_closest(x, y, fluid.r);
          vec3 color = pal(floor(fluid.b * 16.0) / 16.0, vec3(0.8,0.5,0.4),vec3(0.2,0.4,0.2),vec3(2.0,1.0,1.0),vec3(0.5,0.15,0.2) );


          vec3 hsl = rgb2hsv(color);
          hsl.x += 0.12;
          color = hsv2rgb(hsl);


          gl_FragColor = vec4(vec3(final) * color, 1.0);
          gl_FragColor = vec4(vec3(final), 1.0);
      }
    `,
    uniforms: {
      tFluidSim: { value: null },
      tBayer: { value: getBayerTexture(gl) },
      uResolution: { value: new Vec2() },
    },
    depthTest: false,
    depthWrite: false,
  });
};
