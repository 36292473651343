import _ from 'underscore';
import IMOG from '~/lib/imog';
import useWindowSize from '~/lib/imog/use/windowSize';
import { Renderer, Geometry, Program, Color, Mesh, Triangle } from 'ogl';

import rawProgram from './Program/raw';
import valueProgram from './Program/value';
import cellProgram from './Program/cell';
import ditherProgram from './Program/dither';
import ditherColorProgram from './Program/ditherColor';
import paletteProgram from './Program/palette';
import wallProgram from './Program/wall';

const triangle = _.memoize(
  gl =>
    new Geometry(gl, {
      position: { size: 2, data: new Float32Array([-1, -1, 3, -1, -1, 3]) },
      uv: { size: 2, data: new Float32Array([0, 0, 2, 0, 0, 2]) }
    })
);

export default IMOG.Component('Post', {
  options: {
    gui: false
  },

  props() {
    return {
      windowSize: useWindowSize(),
      pr: 1.5,
      size: props => ({
        width: props.windowSize.width,
        height: props.windowSize.height,
        pr: props.pr
      }),
      program: 'wall'
    };
  },

  setup({ options }) {
    if (options.gui) this.setupGUI();

    this.renderer = new Renderer({ dpr: this.props.pr });
    IMOG.inject('gl', this.renderer.gl);
    document.body.appendChild(this.$gl.canvas);
    this.$gl.clearColor(0, 0, 0, 1);

    this.programs = {
      raw: rawProgram(this.$gl),
      value: valueProgram(this.$gl),
      cell: cellProgram(this.$gl),
      dither: ditherProgram(this.$gl),
      ditherColor: ditherColorProgram(this.$gl),
      palette: paletteProgram(this.$gl),
      wall: wallProgram(this.$gl)
    };

    this.outputTri = new Mesh(this.$gl, {
      geometry: triangle(this.$gl),
      program: this.programs.raw
    });
  },

  hooks: {
    'set:size'({ width, height, pr }) {
      this.renderer.setSize(width, height);
      this.programs.dither.uniforms.uResolution.value.set(
        width * 0.5,
        height * 0.5
      );
    },
    'set:program'(key) {
      this.outputTri.program = this.programs[key];
    }
  },

  methods: {
    render(ms) {
      this.$gl.clearColor(0, 0, 0, 1);
      this.renderer.render({
        scene: this.outputTri,
        sort: false,
        update: false
      });
    },
    async setupGUI() {
      await require('~/lib/gui');
      const folder = this.$gui.addFolder(`Post`);
      folder.open();
      folder.add(this.props, 'program', [
        'raw',
        'value',
        'cell',
        'dither',
        'ditherColor',
        'palette',
        'wall'
      ]);
    }
  }
});
